import { navigateToLogin } from '~/utils/externalRouting'

export const useAuthStore = defineStore('auth', () => {
  const config = useRuntimeConfig()
  const url = useRequestURL()
  const message = useMessage()

  const { execute, error } = useApiAuthPostLogout({ server: false })

  const logout = async () => {
    await execute()
    if (!error.value)
      await navigateToLogin(config.public.loginRoute, url)
    else if (error.value.data)
      message.error(error.value.data.message)
  }

  return {
    logout,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
