<script setup lang="ts">
import type { RouteLocationResolved } from '#vue-router'
import type { RouteLocation } from 'vue-router/auto'
import { Icon, NuxtLink } from '#components'
import { type MenuOption, NMenu } from 'naive-ui'

interface MenuRoute {
  name: RouteLocation['name']
  label: string
  icon?: string
}

const menuRoutes: Array<MenuRoute> = [
  {
    name: 'dashboard',
    label: 'Startseite',
    icon: 'i-ic-outline-home',
  },
  {
    name: 'projects',
    label: 'Projekte',
    icon: 'i-ic-outline-folder-copy',
  },
  {
    name: 'clients',
    label: 'Kunden',
    icon: 'i-ic-outline-supervisor-account',
  },
  {
    name: 'reports',
    label: 'Zeitberichte',
    icon: 'i-ic-outline-fact-check',
  },
  {
    name: 'timesheets',
    label: 'Zeiterfassung',
    icon: 'i-ic-outline-timer',
  },
  {
    name: 'recurring-invoices',
    label: 'Automatische Rechnungen',
    icon: 'i-ic-outline-event-repeat',
  },
  {
    name: 'invoices',
    label: 'Rechnungen',
    icon: 'i-ic-outline-file-copy',
  },
]

function mapRoute(route: MenuRoute): MenuOption {
  return {
    key: route.name,
    label: () => h(NuxtLink, { to: { name: route.name } as RouteLocationResolved }, () => route.label),
    icon: route.icon ? () => h(Icon, { name: route.icon! }) : undefined,
  }
}

const { can } = useAppAbility()

const routes = computed(() => {
  return menuRoutes.filter(route => can('read', route.name)).map(mapRoute)
})

const route = useRoute()
</script>

<template>
  <NMenu
    :collapsed-width="64"
    :collapsed-icon-size="22"
    :default-value="String(route.name)"
    :options="routes"
  />
</template>
